import gql from "graphql-tag";

export const GET_DATA = gql`
  query GetCourseDetailsWithIssuedCertificates($course_id: uuid!, $id: uuid!) {
    courses_bb_by_pk(id: $course_id) {
      id
      announcement
      active
      level
      application_close_at
      application_open_at
      approx_participants
      certificate_available
      code_of_conduct_url
      college_id
      is_projects_included
      contact_email
      progression
      course_modules(
        order_by: { order: asc }
        where: { isActive: { _eq: true } }
      ) {
        id
        name
        description
        unlock_on
        course_content_blocks(
          where: { isActive: { _eq: true } }
          order_by: { order: asc }
        ) {
          id
          name
          expected_completion_time
          is_mandatory
          order
          course_block_contents(order_by: { order: asc }) {
            id
            type
            points
            course_block_content_submissions(
              where: { course_bb_participant: { user_id: { _eq: $id } } }
            ) {
              id
              points_gained
            }
          }
          course_content_block_submissions(
            where: {
              course_bb_participant: { user_id: { _eq: $id } }
              status: { _eq: "completed" }
            }
          ) {
            id
          }
        }
        course_sub_modules(
          where: { isActive: { _eq: true } }
          order_by: { order: asc }
        ) {
          id
          name
          order
          description
          is_milestone
          deadline
          course_content_blocks(
            order_by: { order: asc }
            where: { isActive: { _eq: true } }
          ) {
            id
            name
            expected_completion_time
            is_mandatory
            course_block_contents(order_by: { order: asc }) {
              id
              type
              points
              course_block_content_submissions(
                where: { course_bb_participant: { user_id: { _eq: $id } } }
              ) {
                id
                points_gained
              }
            }
            course_content_block_submissions(
              where: {
                course_bb_participant: { user_id: { _eq: $id } }
                status: { _eq: "completed" }
              }
            ) {
              id
            }
          }
        }
      }
      domain_list {
        id
        name
      }
      course_bb_speakers {
        title
        name
        profile_url
        picture_url
        company
        id
        about
      }
      course_bb_skills {
        id
        skill_list {
          name
          id
        }
      }
      course_perks
      cover_image
      creator_logo
      creator_name
      creator_tagline
      description
      highlights
      what_you_will_learn
      pre_requirements
      faqs
      lms_course_id
      has_lms
      domain_id
      ends_at
      start_at
      learning_credits
      logo
      instructions
      course_bb_live_classes(
        where: {
          live_session: {
            ends_at: { _gt: "now()" }
            start_at: { _lte: "now()" }
          }
          is_active: { _eq: true }
        }
      ) {
        id
        message
        live_session {
          link
          isRecurring
          isActive
          excluded_dates
          ends_at
          end_time
          description
          title
          start_time
          start_at
        }
      }
      name
      status
      tagline
      venue
      course_communication_platform
      website
      is_online
      curriculum
      course_bb_participants(where: { user_id: { _eq: $id } }) {
        id
        start_at
        ends_at
        batch_id
      }
    }
  }
`;
export const ENROLL = gql`
  mutation EnrollToTheCourse($course_id: uuid!) {
    insert_course_bb_participants_one(object: { course_id: $course_id }) {
      id
    }
  }
`;

export const GET_COURSE_GOAL_COMPLETION_STATS = gql`
  query GetCourseGoalCompletionStats($course_id: uuid!, $user_id: uuid!) {
    view_course_content_block_stats(
      where: { course_id: { _eq: $course_id }, user_id: { _eq: $user_id } }
    ) {
      completed_mandatory_modules
      completed_modules
      total_mandatory_modules
      total_modules
    }

    view_course_block_contents_stats(
      where: { course_id: { _eq: $course_id }, user_id: { _eq: $user_id } }
    ) {
      percentage
      gained_points
      total_points
    }
  }
`;
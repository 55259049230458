import format from "date-fns/format";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, CardFooter, CardHeader, CardTitle, CardDescription } from "src/components/ui/card"
import { Badge } from "src/components/ui/badge"
import { Button } from "src/components/ui/button"
import { Book, ChevronRight, Gauge, Users } from 'lucide-react'
import { Progress } from "src/components/ui/progress"

function ProjectCard({
  thumbnail,
  timestamp,
  organiser,
  title,
  project_id,
  loading = false,
  domain = [],
  status = "pending",
  members = 0,
  description = "",
  progress = 0,
}) {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/home/project_assignments/${project_id}`)
  }

  if (loading) {
    return <Skeleton />
  }

  return (

    <Card className="group hover:shadow-xl transition-all duration-300 ease-in-out bg-white min-w-72  dark:bg-gray-800 overflow-hidden">
      <CardHeader className="space-y-1 pb-2">
        <div className="flex items-center justify-between">
          <Badge
            variant={status === "completed" ? "default" : status === "ongoing" ? "secondary" : "outline"}
            className="capitalize px-2 py-1"
          >
            {status}
          </Badge>
          <div className="flex items-center gap-1 text-sm text-muted-foreground bg-gray-100 dark:bg-gray-700 px-2 py-1 rounded-full">
            <Users className="h-4 w-4" />
            {members}
          </div>
        </div>
        <CardTitle className="text-xl font-semibold group-hover:text-purple-600 transition-colors">
          {title}
        </CardTitle>
        <CardDescription className="line-clamp-2">{description}</CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="flex items-center justify-between text-sm">
          <div className="flex items-center gap-2 bg-purple-100 dark:bg-purple-900 text-purple-700 dark:text-purple-300 px-2 py-1 rounded-full">
            <Book className="h-4 w-4" />
            <span>{domain[0] || "N/A"}</span>
          </div>
          <div className="flex items-center gap-2 font-medium">
            <Gauge className="h-4 w-4 text-purple-600" />
            <span>{progress}% Complete</span>
          </div>
        </div>
        <Progress value={progress} className="h-2">
          <div
            className="h-full bg-gradient-to-r from-purple-500 to-blue-500 rounded-full"
            style={{ width: `${progress}%` }}
          />
        </Progress>
        <div className="flex items-center justify-between pt-2">
          <span className="text-sm text-muted-foreground">{format(new Date(timestamp), "MMMM do, yyyy")}</span>
          <Button
            variant="ghost"
            size="sm"
            className="group-hover:bg-purple-100 dark:group-hover:bg-purple-900 transition-colors duration-200"
            onClick={handleNavigate}
          >
            View Details
            <ChevronRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
          </Button>
        </div>
      </CardContent>
    </Card>

  )
}



export default ProjectCard;
